import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import VictoryDialog from "src/components/VictoryDialog";
import HelpDialog from "src/components/HelpDialog";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export type ConcentricState = {
  position: number[];
  moves: number;
  solved: boolean;
  lastPlayed: number;
};

export type ConcentricStats = {
  currentStreak: number;
  bestStreak: number;
  averageMoves: number;
  puzzlesAttempted: number;
  puzzlesSolved: number;
  lastSolved?: number | null;
};

interface GameStateType {
  concentricState: ConcentricState;
  setConcentricState: Dispatch<SetStateAction<ConcentricState>>;
  concentricStats: ConcentricStats;
  setConcentricStats: Dispatch<SetStateAction<ConcentricStats>>;
  hasStarted: boolean;
  setStarted: Dispatch<SetStateAction<boolean>>;
  updateStats: (movesMade: number) => void;
  openVictory: () => void;
  openHelp: () => void;
}

const defaultConcentricState: ConcentricState = {
  position: [0, 0, 0, 0, 0],
  moves: 0,
  solved: false,
  lastPlayed: dayjs().unix(),
};
const defaultConcentricStats: ConcentricStats = {
  currentStreak: 0,
  bestStreak: 0,
  averageMoves: 0,
  puzzlesAttempted: 0,
  puzzlesSolved: 0,
  lastSolved: null,
};

const defaultGameState: GameStateType = {
  concentricState: defaultConcentricState,
  setConcentricState: () => {},
  concentricStats: defaultConcentricStats,
  setConcentricStats: () => {},
  hasStarted: false,
  setStarted: () => {},
  updateStats: () => {},
  openVictory: () => {},
  openHelp: () => {},
};

const GameStateContext = React.createContext<GameStateType>(defaultGameState);

// GameStateContext.Provider

// <GameStateContext.Provider value={{}}>{children}</GameStateContext.Provider>

// const value = React.useContext(GameStateContext)

const initState = (
  name: string,
  defaultValue: ConcentricState | ConcentricStats
) => {
  const state = localStorage.getItem(`concentric${name}`);
  if (state) {
    let loadedState = JSON.parse(state);
    if (name === "State") {
      if (!dayjs.unix(loadedState.lastPlayed).isToday()) {
        return defaultValue;
      }
    }
    if (name === "Stats") {
      if (
        !(
          dayjs.unix(loadedState.lastSolved).isYesterday() ||
          dayjs.unix(loadedState.lastSolved).isToday()
        )
      ) {
        loadedState.currentStreak = 0;
      }
    }
    return loadedState;
  } else {
    localStorage.setItem(`concentric${name}`, JSON.stringify(defaultValue));
    return defaultValue;
  }
};

const initPlayedBefore = () => {
  const playedBefore = localStorage.getItem("concentricPlayedBefore");
  if (playedBefore === "true") {
    return true;
  } else {
    return false;
  }
};

export const GameStateContextProvider: React.FC<{}> = (props) => {
  let [concentricState, setConcentricState] = useState<ConcentricState>(
    initState("State", defaultConcentricState)
  );

  let [concentricStats, setConcentricStats] = useState<ConcentricStats>(
    initState("Stats", defaultConcentricStats)
  );

  let [playedBefore, setPlayedBefore] = useState(initPlayedBefore());

  const updateStats = (movesMade: number) => {
    let currentBestStreak = concentricStats.bestStreak;
    let newCurrentStreak: number;
    const currentTotalMoves =
      concentricStats.averageMoves * concentricStats.puzzlesSolved;
    const newTotalMoves = currentTotalMoves + movesMade;
    const newPuzzlesSolved = concentricStats.puzzlesSolved + 1;
    const newAverageMoves = newTotalMoves / newPuzzlesSolved;

    if (concentricStats.lastSolved) {
      if (dayjs.unix(concentricStats.lastSolved).isYesterday()) {
        newCurrentStreak = concentricStats.currentStreak + 1;
        if (newCurrentStreak > currentBestStreak) {
          currentBestStreak = newCurrentStreak;
        }
      } else {
        newCurrentStreak = 1;
      }
    } else {
      newCurrentStreak = 1;
      currentBestStreak = 1;
    }

    setConcentricStats((prev: any) => ({
      ...prev,
      currentStreak: newCurrentStreak,
      bestStreak: currentBestStreak,
      averageMoves: newAverageMoves,
      puzzlesSolved: newPuzzlesSolved,
      lastSolved: dayjs().unix(),
    }));
  };

  let [hasStarted, setStarted] = useState(false);

  // TODO: maybe move out
  const [showVictoryDialog, setShowVictoryDialog] = useState(false);
  const openVictory = () => {
    setShowHelpDialog(false);
    setShowVictoryDialog(true);
  };
  const closeVictory = () => {
    setShowVictoryDialog(false);
  };

  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const openHelp = () => {
    setShowVictoryDialog(false);
    setShowHelpDialog(true);
  };
  const closeHelp = () => {
    setShowHelpDialog(false);
  };

  useEffect(() => {
    if (!playedBefore) {
      openHelp();
      setPlayedBefore(true);
      localStorage.setItem("concentricPlayedBefore", "true");
    }
    if (concentricState.solved) openVictory();
    if (hasStarted) {
      localStorage.setItem("concentricState", JSON.stringify(concentricState));
      localStorage.setItem("concentricStats", JSON.stringify(concentricStats));
    }
  }, [concentricState, concentricStats, hasStarted]);

  const { children } = props;
  return (
    <GameStateContext.Provider
      value={{
        concentricState,
        setConcentricState,
        concentricStats,
        setConcentricStats,
        hasStarted,
        setStarted,
        updateStats,
        openVictory,
        openHelp,
      }}
    >
      {children}
      <VictoryDialog
        showVictoryDialog={showVictoryDialog}
        closeVictory={closeVictory}
      />
      <HelpDialog showHelpDialog={showHelpDialog} closeHelp={closeHelp} />
    </GameStateContext.Provider>
  );
};

export const useGameState = () => {
  return React.useContext(GameStateContext);
};
