import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import Layout from "./views/Layout";
import Game from "./views/Game";
import ReactGA from "react-ga";
import { GameStateContextProvider } from "./context/GameState";

ReactGA.initialize("UA-226860012-2");
ReactGA.pageview(window.location.pathname + window.location.search);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC<{}> = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <GameStateContextProvider>
        <Layout>
          <Game />
        </Layout>
      </GameStateContextProvider>
    </QueryClientProvider>
  );
};

export default App;
