import { P5Instance, SketchProps } from "react-p5-wrapper";

export type MySketchProps = SketchProps & {
  width: number;
  height: number;
  puzzle: string[][];
  checkSolution: (positions: number[], movesMade: number) => void;
  solved: boolean;
  hasStarted: boolean;
  startingMoves: number;
  startingPosition: number[];
};

export function sketch(p5: P5Instance<MySketchProps>) {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let lettersPerRing = 6;
  let ringCount = 5;
  let angleBetweenCells: any;
  let movesMade = 0;

  let ringSpacing = 3;
  let ringWidth = 30;
  let cellRadius = ringWidth / 2;

  let boardRotation = 0;
  let rings: Ring[] = [];
  let currentRing = ringCount - 1;

  // let minutes = 0;
  // let seconds = 0;
  // let counter = 0;

  let solved = false;

  var dragStart = false;
  let angleOffset: number;
  let mouseDown = false;

  let gameStarted = false;
  let puzzle: string[][];
  let checkSolution: (positions: number[], movesMade: number) => void;
  let hasStarted = false;

  let startingPosition = [1, 1, 1, 1, 1];
  let prevPositions = [0, 0, 0, 0, 0];

  const moveRight = (arr: string[]) => arr.unshift(arr.pop() || "");

  function createRings() {
    for (let i = 0; i < ringCount; i++) {
      let letters = puzzle[i];
      for (let p = 0; p < startingPosition[i]; p++) {
        moveRight(letters);
      }
      let ring = new Ring(
        i,
        letters,
        0,
        0,
        ringWidth + (ringWidth + ringSpacing) * i + ringSpacing
      );
      rings.push(ring);
    }
  }

  // function stopWatch() {
  // if (!solved) {
  // counter++;
  // minutes = Math.floor(counter / 60);
  // seconds = counter % 60;
  // }
  // }

  function getNearestGridAngle(a: number) {
    return Math.round(a / angleBetweenCells) * angleBetweenCells;
  }

  function getRingFromClick() {
    let d =
      p5.dist(p5.mouseX, p5.mouseY, p5.width / 2, p5.height / 2) -
      ringWidth / 2;
    let rIndex = d / (ringSpacing + ringWidth);

    if (rIndex > ringCount) {
      rIndex = ringCount;
    } else if (rIndex < 0) {
      rIndex = 0;
    }

    rIndex = Math.floor(rIndex);

    return rIndex;
  }

  // Might want for boggle?
  // function checkWords() {
  //   let currWords: string[];
  //   currWords = [];

  //   for (let i = lettersPerRing - 1; i >= 0; i--) {
  //     let word = "";
  //     rings.forEach((ring) => {
  //       let t = ring.cells.find((c) => {
  //         return c.ringPosition === i;
  //       });

  //       if (t) {
  //         word = t.value + word;
  //       }
  //     });
  //     currWords.push(word);
  //   }

  //   // if (arrayEquals(currWords, exampleWords)) {
  //   //   console.log("SOLVED");
  //   //   solved = true;
  //   // } else {
  //   //   solved = false;
  //   // }
  // }

  function arrayEquals(a: any[], b: any[]) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  function submitSolution() {
    let positions = rings.map((ring) => ring.position);
    let adjustedPositions = positions.map((num, idx) => {
      if (startingPosition) {
        return (num + startingPosition[idx]) % 6;
      } else {
        return num;
      }
    });
    if (!solved) {
      checkSolution(adjustedPositions, movesMade);
    }
  }

  const hasSetupFinished = () => {
    for (let i = 0; i < rings.length; i++) {
      let ring = rings[i];
      if (ring.moving) {
        return false;
      }
    }
    return true;
  };

  const start = () => {
    hasStarted = true;
    // enable dragging
    // setInterval(stopWatch, 1000);
  };

  p5.setup = () => p5.createCanvas(window.innerWidth, window.innerHeight);

  p5.updateWithProps = (props) => {
    puzzle = props.puzzle;
    checkSolution = props.checkSolution;
    if (props.solved) {
      solved = true;
    }
    if (!hasStarted && props.hasStarted) {
      movesMade = props.startingMoves;
      startingPosition = props.startingPosition;
      start();
    }
  };

  p5.windowResized = () => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight);
  };

  p5.mousePressed = () => {
    if (hasStarted) {
      dragStart = true;
      mouseDown = true;
    }
  };

  p5.mouseReleased = () => {
    if (hasStarted) {
      if (rings[currentRing]) {
        if (!solved) {
          const nearestAngle = getNearestGridAngle(rings[currentRing].angle);
          let nearestAngleInRange = nearestAngle % (2 * Math.PI);
          if (nearestAngleInRange < 0) {
            nearestAngleInRange = 2 * Math.PI + nearestAngleInRange;
          }
          let newPosition = 0;
          if (nearestAngleInRange !== 0) {
            newPosition = Math.abs(
              Math.round(
                p5.map(nearestAngleInRange, 0, (2 * Math.PI * 5) / 6, 0, 5)
              )
            );
          }
          rings[currentRing].targetAngle = nearestAngle;
          rings[currentRing].position = newPosition;

          rings[currentRing].cells.forEach((cell) => {
            let v = (cell.index + newPosition) % lettersPerRing;
            cell.ringPosition = v;
          });

          // Check it's actually a new position before counting the move
          let positions = rings.map((ring) => ring.position);
          if (gameStarted && !arrayEquals(positions, prevPositions))
            movesMade++;

          prevPositions = positions.slice();
        }
        rings[currentRing].isSelected = false;
      }
      mouseDown = false;
    }
  };

  p5.mouseDragged = () => {
    if (hasStarted) {
      let newAngle = Math.atan2(
        p5.mouseY - p5.height / 2,
        p5.mouseX - p5.width / 2
      );

      if (dragStart) {
        currentRing = getRingFromClick();

        if (currentRing === 5) {
          angleOffset = newAngle - boardRotation;
        }

        if (rings[currentRing]) {
          angleOffset = newAngle - rings[currentRing].angle;
          rings[currentRing].isSelected = true;
        }
      }
      dragStart = false;

      if (rings[currentRing]) {
        rings[currentRing].angle = newAngle - angleOffset;
      } else {
        boardRotation = newAngle - angleOffset;
      }
    }

    return false;
  };

  p5.setup = () => {
    p5.createCanvas(width, height);
    p5.noStroke();
    angleBetweenCells = (2 * Math.PI) / lettersPerRing;
  };

  p5.draw = () => {
    if (puzzle && rings.length === 0) {
      createRings();
    }

    p5.background(33, 35, 35);

    p5.push();
    p5.translate(p5.width / 2, p5.height / 2);
    p5.rotate(boardRotation);

    p5.push();
    p5.fill(176, 206, 255, 50);
    // p5.circle(
    //   0,
    //   0,
    //   2 * ((ringCount + 1) * ringSpacing + (ringCount + 1) * ringWidth)
    // );
    p5.pop();

    rings.forEach((item) => {
      item.draw();
    });

    if (!gameStarted && hasSetupFinished()) {
      gameStarted = true;
    }

    p5.pop();

    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.textSize(30);
    p5.fill(240, 240, 240);
    // p5.text(movesMade + " moves", p5.width / 2, 100);
    p5.textSize(16);
    // p5.text(
    //   minutes.toString().padStart(2, "0") +
    //     ":" +
    //     seconds.toString().padStart(2, "0"),
    //   p5.width / 2,
    //   125
    // );
  };

  class Cell {
    ringId: number;
    index: number;
    value: string;
    ringPosition: number;
    x: number;
    y: number;
    r: number;

    constructor(
      index: number,
      value: string,
      ringId: number,
      x: number,
      y: number,
      r: number
    ) {
      this.ringId = ringId;
      this.index = index;
      this.value = value;
      this.ringPosition = index;
      this.x = x;
      this.y = y;
      this.r = r;
    }

    draw() {
      solved ? p5.fill(103, 171, 87) : p5.fill(255, 255, 255);
      if (rings[this.ringId]) {
        if (rings[this.ringId].isSelected) {
          p5.fill(250, 250, 250, 125);
        }
      }

      p5.circle(this.x, this.y, this.r * 2);
      p5.push();
      p5.translate(this.x, this.y);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.fill(0, 0, 0);
      p5.textSize(20);
      p5.rotate(((2 * p5.PI) / lettersPerRing) * this.index + p5.PI / 2);
      if (hasStarted) {
        p5.text(this.value, 0, 0);
      }
      p5.pop();
    }
  }

  class Ring {
    index: number;
    x: number;
    y: number;
    r: number;
    angle: number;
    targetAngle: number;
    cells: Cell[];
    letters: string[];
    moving: boolean;
    isSelected: boolean;
    position: number;

    constructor(
      index: number,
      letters: string[],
      x: number,
      y: number,
      r: number
    ) {
      this.index = index;
      this.x = x;
      this.y = y;
      this.r = r;
      this.angle = 0;
      // this.targetAngle = getNearestGridAngle(p5.radians(p5.random(360)));
      // Make this 0 +/- > 2PI/6 to get intial set up animation
      this.targetAngle = 0;
      this.cells = [];
      this.letters = letters;
      this.generateCells();
      this.moving = false;
      this.isSelected = false;
      this.position = 0;
    }

    generateCells() {
      for (let i = 0; i < lettersPerRing; i++) {
        let position = this.positionCell(i);
        let newCell = new Cell(
          i,
          this.letters[i],
          this.index,
          position.x,
          position.y,
          cellRadius
        );
        this.cells.push(newCell);
      }
    }

    positionCell(index: number) {
      let px = this.r * Math.cos(index * angleBetweenCells) + this.x;
      let py = this.r * Math.sin(index * angleBetweenCells) + this.y;
      return p5.createVector(px, py);
    }

    draw() {
      if (mouseDown === false) {
        if (p5.abs(this.angle - this.targetAngle) > 0.005) {
          this.moving = true;
          this.angle = p5.lerp(this.angle, this.targetAngle, 0.1);
        } else {
          if (this.moving) {
            this.angle = this.targetAngle;
            this.moving = false;
            // Submit solution when ring has finished moving
            submitSolution();
          }
        }
      }

      p5.push();

      p5.rotate(this.angle);
      // Draw circle connecting rings
      p5.noFill();
      p5.stroke(230, 230, 230, 75);
      p5.circle(this.x, this.y, this.r * 2);
      p5.noStroke();
      p5.fill(255, 255, 255);
      // For each cell in ring, draw over the top of the line
      this.cells.forEach((item) => {
        item.draw();
      });
      p5.pop();
    }
  }
}
