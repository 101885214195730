import React, { useState, useEffect } from "react";
import { sketch } from "../../sketches/concentric/concentric-new";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { getTodaysPuzzle, PuzzleContent, PuzzleData } from "../../services/api";

import { ReactComponent as LoadingSpinner } from "../../images/spinner.svg";
import { ReactComponent as MoveSvg } from "../../icons/rotate_right_black_24dp.svg";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

import ReactGA from "react-ga";
import { useGameState } from "src/context/GameState";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface ComponentProps {
  //Your component props
}

export type ConcentricState = {
  position: number[];
  moves: number;
  solved: boolean;
  lastPlayed: number;
};

export type ConcentricStats = {
  currentStreak: number;
  bestStreak: number;
  averageMoves: number;
  puzzlesAttempted: number;
  puzzlesSolved: number;
  lastSolved?: number | null;
};

const Game: React.FC<ComponentProps> = (props: ComponentProps) => {
  const {
    concentricState,
    setConcentricState,
    concentricStats,
    setConcentricStats,
    hasStarted,
    setStarted,
    updateStats,
  } = useGameState();

  let [concentricPuzzle, setConcentricPuzzle] = useState<PuzzleContent>();

  useEffect(() => {
    const fetchPuzzle = () => {
      getTodaysPuzzle(dayjs().format("YYYY-MM-DD")).then((data: PuzzleData) => {
        let fetchedPuzzle = data.data;
        fetchedPuzzle.content = JSON.parse(fetchedPuzzle.content);
        localStorage.setItem("concentricPuzzle", JSON.stringify(fetchedPuzzle));
        setConcentricPuzzle(fetchedPuzzle);
        setStarted(true);
      });
    };

    const initPuzzle = () => {
      const puzzle = localStorage.getItem("concentricPuzzle");
      if (puzzle) {
        const loadedPuzzle = JSON.parse(puzzle);
        setConcentricPuzzle(loadedPuzzle);
        if (!dayjs.unix(loadedPuzzle.date._seconds).isToday()) {
          fetchPuzzle();
        } else {
          setStarted(true);
        }
      } else {
        fetchPuzzle();
      }
    };

    initPuzzle();
  }, [concentricState, setStarted]);

  const allEqual = (arr: any[]) => arr.every((v) => v === arr[0]);

  const checkSolution = (positions: number[], movesMade: number) => {
    if (movesMade === 1) {
      // An attempt has started on the puzzle
      setConcentricStats((prev: any) => ({
        ...prev,
        puzzlesAttempted: concentricStats.puzzlesAttempted + 1,
      }));

      ReactGA.event({
        category: "User",
        action: "Puzzle started",
      });
    }

    setConcentricState((prev: any) => ({
      ...prev,
      moves: movesMade,
      position: positions,
    }));

    let total = positions.map((num, idx) => {
      if (concentricPuzzle) {
        return (num + concentricPuzzle.solution[idx]) % 6;
      } else {
        return num;
      }
    });

    if (allEqual(total)) {
      setConcentricState((prev: any) => ({
        ...prev,
        solved: true,
      }));
      updateStats(movesMade);
      ReactGA.event({
        category: "User",
        action: "Puzzle solved",
        value: movesMade,
      });
    }
  };

  if (concentricPuzzle && concentricState.position && hasStarted) {
    return (
      <>
        <div className="text-white absolute m-auto w-full mt-20">
          <div className="text-center">
            <span className=" text-gray-300 text-xl font-bold mr-2 px-2.5 py-0.5 pb-1 rounded  dark:text-gray-300">
              <MoveSvg className="text-white inline mr-1" />
              {concentricState?.moves}
              {concentricState.moves === 1 ? " move" : " moves"}
            </span>
          </div>
        </div>
        <ReactP5Wrapper
          sketch={sketch}
          width={window.innerWidth}
          height={window.innerHeight}
          puzzle={concentricPuzzle.content}
          checkSolution={checkSolution}
          solved={concentricState.solved}
          hasStarted={hasStarted}
          startingMoves={concentricState.moves}
          startingPosition={concentricState.position}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="flex justify-center items-center">
          <div className="animate-spin inline-block w-8 h-8 m-10" role="status">
            <LoadingSpinner />
          </div>
        </div>
      </>
    );
  }
};

export default Game;
