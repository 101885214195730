import React from "react";
import { ReactComponent as HelpSvg } from "../icons/question_mark_black_24dp.svg";
import { ReactComponent as StatsSvg } from "../icons/query_stats_black_24dp.svg";
import { useGameState } from "src/context/GameState";
// import VictoryDialog from "src/components/VictoryDialog";

const Nav: React.FC<{}> = (props) => {
  const { openVictory, openHelp } = useGameState();

  return (
    <>
      <div className="bg-regal-slate h-12 absolute inset-x-0 top-0 text-white flex items-center content-center justify-start px-4 drop-shadow-lg z-10">
        <div>
          <button
            className="content-center"
            onClick={() => window.location.reload()}
          >
            <h1 className="font-bold text-xl">C◎NCENTRICK</h1>
          </button>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-2">
          <button
            type="button"
            onClick={openVictory}
            className="bg-regal-gray p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span className="sr-only">Statistics</span>
            <StatsSvg className="text-white" />
          </button>
          <button
            type="button"
            onClick={openHelp}
            className="bg-regal-gray p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span className="sr-only">Help</span>
            <HelpSvg className="text-white" />
          </button>
        </div>
      </div>
      <div className="h-12" />
    </>
  );
};

export default Nav;
