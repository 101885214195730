import React, { useEffect, useState } from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import StatsPanel from "../components/StatsPanel";

import { ReactComponent as MoveSvg } from "../icons/rotate_right_black_24dp.svg";
import { useGameState } from "src/context/GameState";
import dayjs from "dayjs";

interface VictoryDialogProps {
  showVictoryDialog: boolean;
  closeVictory: (event: any) => void;
}

const VictoryDialog: React.FC<VictoryDialogProps> = (
  props: VictoryDialogProps
) => {
  const { showVictoryDialog, closeVictory } = props;
  const { concentricState, concentricStats } = useGameState();
  let [timeUntil, setTimeUntil] = useState("00:00");

  const title = concentricState.solved ? "Congratulations!" : "Statistics";
  const movesColor = concentricState.solved ? "bg-green-900" : "bg-gray-700";

  useEffect(() => {
    const intervalId = setInterval(() => {
      const startOfNewDay = dayjs().add(1, "day").startOf("day");
      const hoursToNewPuzzle = startOfNewDay.diff(dayjs(), "hours") % 24;
      const minutesToNewPuzzle = startOfNewDay.diff(dayjs(), "minutes") % 60;
      const secondsToNewPuzzle = startOfNewDay.diff(dayjs(), "seconds") % 60;
      const timeUntilNewPuzzle = `${hoursToNewPuzzle
        .toString()
        .padStart(2, "0")}:${minutesToNewPuzzle
        .toString()
        .padStart(2, "0")}:${secondsToNewPuzzle.toString().padStart(2, "0")}`;
      setTimeUntil(timeUntilNewPuzzle);
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [timeUntil]);

  const handleShare = async () => {
    try {
      await navigator.share({
        url: "https://concentrick.app",
        text: `I just solved today's daily C◎NCENTRICK puzzle in ${
          concentricState.moves
        } move${concentricState.moves === 1 ? "" : "s"}!`,
        title: "C◎NCENTRICK",
      });
      console.log("Successful share");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog
        aria-label="Victory"
        isOpen={showVictoryDialog}
        onDismiss={closeVictory}
        style={{
          padding: 0,
          background: "rgba(0,0,0,0)",
          width: "90%",
        }}
      >
        <div className="p-6 max-w-2xl rounded-lg shadow-md bg-regal-slate dark:bg-regal-slate content-center m-auto">
          <div className="flex">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white flex-auto">
              {title}
            </h5>
            <button
              className="close-button text-white flex-none focus:outline-none"
              onClick={closeVictory}
            >
              <span aria-hidden className="focus:outline-none">
                x
              </span>
            </button>
          </div>
          <div className="grid grid-rows-1 grid-cols-6 gap-y-4 mt-5">
            <div className="row-span-1 col-span-3 my-auto text-white font-bold">
              Today's Puzzle
            </div>
            <div className="row-span-1 col-span-3 m-auto">
              <span
                className={`${movesColor} text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:${movesColor} dark:text-gray-300`}
              >
                <MoveSvg className="text-white inline mr-1" />
                {concentricState.solved ? concentricState.moves : "-"}
              </span>
            </div>
            <div className="row-span-1 col-span-6 my-auto">
              <hr className="text-gray-800 border-gray-700 dark:border-gray-700" />
            </div>
            <StatsPanel concentricStats={concentricStats} />
            {concentricState.solved && (
              <>
                <div className="row-span-1 col-span-6 my-auto">
                  <hr className="text-gray-800 border-gray-700 dark:border-gray-700" />
                </div>
                <div className="row-span-1 col-span-6 m-auto">
                  <div className="text-white italic">
                    {timeUntil} until next puzzle!
                  </div>
                </div>
                <div className="row-span-1 col-span-6 m-auto">
                  <button
                    className="bg-transparent hover:bg-regal-gray text-white font-semibold hover:text-white py-2 px-4 border border-white-500 hover:border-transparent rounded"
                    onClick={handleShare}
                  >
                    Share
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default VictoryDialog;
