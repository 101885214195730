import betterFetch from "src/util/betterFetch";

export type FirebaseDate = {
  _seconds: number;
  _nanoseconds: number;
};

export type PuzzleContent = {
  id: string;
  content: string;
  solution: number[];
  date: FirebaseDate;
};

export type PuzzleData = {
  status: string;
  message: string;
  data: PuzzleContent;
};

export async function getTodaysPuzzle(date: string) {
  const result = betterFetch<PuzzleData>(
    `${process.env.REACT_APP_BACKEND_API_URL}/puzzle/today?` +
      new URLSearchParams({
        date,
      }),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_API_KEY}`,
      },
      query: {
        date,
      },
    }
  );
  return result;
}
