import React from "react";

import { ReactComponent as MoveSvg } from "../icons/rotate_right_black_24dp.svg";
import { ReactComponent as TimerSvg } from "../icons/timer_black_24dp.svg";
import { ReactComponent as StreakSvg } from "../icons/local_fire_department_black_24dp.svg";
import { ConcentricStats } from "src/views/Game/Game";

interface StatsPanelProps {
  concentricStats: ConcentricStats;
}

const StatsPanel: React.FC<StatsPanelProps> = (props: StatsPanelProps) => {
  const { concentricStats } = props;
  const showTime = false;

  return (
    <>
      {showTime && (
        <>
          <div className="row-span-1 col-span-2 my-auto text-white font-bold">
            Your Average
          </div>
          <div className="row-span-1 col-span-2 m-auto">
            <span className="bg-blue-900 text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:bg-blue-900 dark:text-gray-300">
              <MoveSvg className="text-white inline mr-1" />
              {concentricStats.averageMoves}
            </span>
          </div>
          <div className="row-span-1 col-span-2 m-auto">
            <span className="bg-blue-900 text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:bg-blue-900 dark:text-gray-300">
              <TimerSvg className="text-white inline mr-2" />
              {"1".padStart(2, "0")}:{"14".padStart(2, "0")}
            </span>
          </div>
        </>
      )}
      {!showTime && (
        <>
          <div className="row-span-1 col-span-3 my-auto text-white font-bold">
            Your Average
          </div>
          <div className="row-span-1 col-span-3 m-auto">
            <span className="bg-blue-900 text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:bg-blue-900 dark:text-gray-300">
              <MoveSvg className="text-white inline mr-1" />
              {concentricStats.averageMoves.toFixed(1)}
            </span>
          </div>
        </>
      )}
      <div className="row-span-1 col-span-3 my-auto text-white font-bold">
        Current Streak
      </div>
      <div className="row-span-1 col-span-3 m-auto">
        <span className="bg-gray-700 text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:bg-gray-700 dark:text-gray-300 w-full">
          <StreakSvg className="text-white inline mr-1" />{" "}
          {concentricStats.currentStreak}
        </span>
      </div>

      <div className="row-span-1 col-span-3 my-auto text-white font-bold">
        Best Streak
      </div>
      <div className="row-span-1 col-span-3 m-auto">
        <span className="bg-orange-800 text-gray-300 text-lg font-bold mr-2 px-2.5 py-0.5 pb-1 rounded dark:bg-orange-800 dark:text-gray-300 w-full">
          <StreakSvg className="text-white inline mr-1" />{" "}
          {concentricStats.bestStreak}
        </span>
      </div>
    </>
  );
};

export default StatsPanel;
