import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import tutorialAnimation from "../images/tutorial.webp";

interface HelpDialogProps {
  showHelpDialog: boolean;
  closeHelp: (event: any) => void;
}

const HelpDialog: React.FC<HelpDialogProps> = (props: HelpDialogProps) => {
  const { showHelpDialog, closeHelp } = props;

  return (
    <>
      <Dialog
        aria-label="How to play"
        isOpen={showHelpDialog}
        onDismiss={closeHelp}
        style={{
          padding: 0,
          background: "rgba(0,0,0,0)",
          width: "90%",
        }}
      >
        <div className="p-6 max-w-2xl rounded-lg shadow-md bg-regal-slate dark:bg-regal-slate content-center m-auto">
          <div className="flex">
            <h5 className="text-white mb-2 text-2xl font-bold tracking-tight dark:text-white flex-auto">
              How to play
            </h5>
            <button
              className="close-button text-white flex-none focus:outline-none"
              onClick={closeHelp}
            >
              <span aria-hidden className="focus:outline-none">
                x
              </span>
            </button>
          </div>

          <ul>
            <li className="font-normal text-gray-400 dark:text-gray-400 ml-2">
              &#8226; Drag any concentric ring either clockwise or
              anti-clockwise to change its position.
            </li>
            <li className="font-normal text-gray-400 dark:text-gray-400 ml-2">
              &#8226; Drag outside of the rings to rotate the whole puzzle.
            </li>
          </ul>
          <picture className="w-1/2">
            <img
              src={tutorialAnimation}
              className="w-3/4 mx-auto"
              alt="Tutorial"
            />
          </picture>
          <p className="font-extrabold text-gray-400 dark:text-gray-400">
            The puzzle is solved when each column of letters makes a valid word.
            Try to find them as quickly as possible!
          </p>
          <br />
          <p className="font-normal text-gray-400 dark:text-gray-400 italic">
            A new concentric puzzle will be available every day!
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default HelpDialog;
