import React from "react"
import Nav from "./Nav"

const Layout: React.FC<{}> = (props) => {
  const { children } = props

  return (
    <>
      <Nav />
      {children}
    </>
  )
}

export default Layout
